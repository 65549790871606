<template>
  <span>
    <v-row dense justify="center" class="mt-5">
      <v-col
        v-for="indicador in listaKpisCardInicial" 
        :key="indicador"
        cols="5" sm="3" md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-title>
            <v-tooltip bottom v-if="traducaoIndicadores[indicador] != null">
              <template v-slot:activator="{ on }">
                <h5 v-on="on">{{ indicador | toUpperCase }}</h5>
              </template>
              <span >{{ traducaoIndicadores[indicador] }}</span>
            </v-tooltip>
            <h5 v-else >{{ indicador | toUpperCase }}</h5>
            <status-evolucao-kpis
              class="ml-1"
              :valorAtual="dadosKpisTotalizados[indicador] || 0"
              :valorAnterior="dadosKpisTotalizadosCompetenciaAnterior[indicador] || 0"
            />
          </v-card-title>
          <v-card-text>
            <div class="display-3">
              {{ dadosKpisTotalizados[indicador] | parseNumberToFloatBrIgnoringNaN }} 
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <dashboard-faturamento-visao-aneel-cards-tipologias 
      :dadosTipologiaReclamacoes="dadosTipologiaReclamacoes"
      :dadosTipologiaFaturasSemLeitura="dadosTipologiaFaturasSemLeitura"
      :dadosTipologiaAcertosFaturamento="dadosTipologiaAcertosFaturamento"
      :exportingOptions="exportingOptions"
    />

    <dashboard-faturamento-visao-aneel-card-reclamacoes-abertas
      :categories="categories"
      :dados="dadosReclamacoesAbertas"
      :exportingOptions="exportingOptions"
    />

    <dashboard-faturamento-visao-aneel-cards-kpis-por-competencia 
      :dados="dadosCardsKpisPorCompetencia"
      :metasKpis="metasKpis"
      :categories="categories"
      :exportingOptions="exportingOptions"
    />
  </span>
</template>

<script>

import DashboardFaturamentoService from "@/services/DashboardFaturamentoService";

import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
HighchartsNoData(Highcharts);
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
exporting(Highcharts);
exportData(Highcharts);

import dayjs from 'dayjs';

export default {
  name: 'DashboardLnoVisaoAneel',
  components: {
    StatusEvolucaoKpis: () => import("@/components/dashboardLno/StatusEvolucaoKpis.vue"),
    DashboardFaturamentoVisaoAneelCardsTipologias: () => import("@/components/dashboardFaturamento/DashboardFaturamentoVisaoAneelCardsTipologias.vue"),
    DashboardFaturamentoVisaoAneelCardReclamacoesAbertas: () => import("@/components/dashboardFaturamento/DashboardFaturamentoVisaoAneelCardReclamacoesAbertas.vue"),
    DashboardFaturamentoVisaoAneelCardsKpisPorCompetencia: () => import("@/components/dashboardFaturamento/DashboardFaturamentoVisaoAneelCardsKpisPorCompetencia.vue"),
  },
  props: {
    competencia: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    activatedTab: {
      type: Number,
      required: true,
    },
    traducaoIndicadores: {
      type: Object,
      default: {}
    },
    regionalSelecionada: {
      type: Number,
      default: 0,
    }
  },
  data: () => ({
    listaKpisCardInicial: [
      "qrt",
      "prp",
      "psl",
      "prf",
      "pcf"
    ],
    dadosKpisTotalizados: {},
    metasKpis: {},
    dadosKpisTotalizadosCompetenciaAnterior: {},
    dadosTipologiaReclamacoes: {},
    dadosTipologiaFaturasSemLeitura: {},
    dadosTipologiaAcertosFaturamento: {},
    dadosReclamacoesAbertas: {},
    dadosCardsKpisPorCompetencia: {},
    exportingOptions:{
      enabled: true,

        buttons: {
            contextButton: {
              align: 'left',
              verticalAlign: 'bottom',
              menuItems: [
              {
                        textKey: 'downloadPNG',
                        text: 'Baixar como PNG',
                        onclick: function () {
                            this.exportChart();
                        }
                    },
                    {
                        separator: true
                    },
                    {
                        textKey: 'downloadCSV',
                        text: 'Baixar como CSV',
                        onclick: function () {
                            this.downloadCSV();
                        }
                    },
                    {
                        textKey: 'downloadXLS',
                        text: 'Baixar como XLS',
                        onclick: function () {
                            this.downloadXLS();
                        }
                    },
              ]
            }
        }
    }
  }),
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      // Apenas atualiza os dados se for a visao selecionada
      if (this.activatedTab !== 1) return; 

      this.$emit("updateLoadingDados", true);

      Promise.all([
        this.getDadosKpisTotalizados(),
        this.getMetasKpisGeral(),
        this.getDadosKpisTotalizadosCompetenciaAnterior(),
        this.getKpisTotalgetDadosTipologiaReclamacoesizados(),
        this.getDadosTipologiaFaturasSemLeitura(),
        this.getDadosTipologiaAcertosFaturamento(),
        this.getDadosReclamacoesAbertas(),
        this.getDadosKpisPorCompetencia()
      ])
        .catch(err => {
          console.error(err);
          this.$toast.error('Erro recuperar dados.', '', { position: 'topRight' });
        })
        .finally(() => this.$emit("updateLoadingDados", false));
    },
    getDadosKpisTotalizados() {
      return DashboardFaturamentoService.getKpisTotalizados(
        this.competencia,
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosKpisTotalizados = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getMetasKpisGeral() {
      return DashboardFaturamentoService.getMetasKpisGeral(
        "2023-01",
        dayjs().format("YYYY-MM"),
      )
        .then(res => {
          this.metasKpis = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosKpisTotalizadosCompetenciaAnterior() {
      return DashboardFaturamentoService.getKpisTotalizados(
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'), 
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosKpisTotalizadosCompetenciaAnterior = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getKpisTotalgetDadosTipologiaReclamacoesizados() {
      return DashboardFaturamentoService.getKpisTotalgetDadosTipologiaReclamacoesizados(
        this.competencia,
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosTipologiaReclamacoes = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosTipologiaFaturasSemLeitura() {
      return DashboardFaturamentoService.getDadosTipologiaFaturasSemLeitura(
        this.competencia,
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosTipologiaFaturasSemLeitura = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosTipologiaAcertosFaturamento() {
      return DashboardFaturamentoService.getDadosTipologiaAcertosFaturamento(
        this.competencia,
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosTipologiaAcertosFaturamento = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosReclamacoesAbertas() {
      return DashboardFaturamentoService.getDadosReclamacoesAbertas(
        "2023-01",
        dayjs().format("YYYY-MM"),
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosReclamacoesAbertas = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosKpisPorCompetencia() {
      return DashboardFaturamentoService.getDadosKpisPorCompetencia(
        "2023-01",
        dayjs().format("YYYY-MM"),
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosCardsKpisPorCompetencia = res;
        })
        .catch(err => {
          throw err;
        });
    },
  },
  watch: {
    activatedTab() {
      this.getDados();
    },
    competencia() {
      this.getDados();
    },
    regionalSelecionada() {
      this.getDados();
    }
  }
}

</script>

<style>

</style>